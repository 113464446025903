var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import classNames from 'classnames';
import RcUpload from 'rc-upload';
import useMergedState from 'rc-util/lib/hooks/useMergedState';
import * as React from 'react';
import { ConfigContext } from '../config-provider';
import LocaleReceiver from '../locale-provider/LocaleReceiver';
import defaultLocale from '../locale/default';
import warning from '../_util/warning';
import { UploadProps } from './interface';
import UploadList from './UploadList';
import { file2Obj, getFileItem, removeFileItem, updateFileList } from './utils';
export const LIST_IGNORE = `__LIST_IGNORE_${Date.now()}__`;
export { UploadProps };
const InternalUpload = (props, ref) => {
    const { fileList, defaultFileList, onRemove, showUploadList, listType, onPreview, onDownload, onChange, onDrop, previewFile, disabled, locale: propLocale, iconRender, isImageUrl, progress, prefixCls: customizePrefixCls, className, type, children, style, itemRender, maxCount, } = props;
    const [mergedFileList, setMergedFileList] = useMergedState(defaultFileList || [], {
        value: fileList,
        postState: list => list !== null && list !== void 0 ? list : [],
    });
    const [dragState, setDragState] = React.useState('drop');
    const upload = React.useRef();
    warning('fileList' in props || !('value' in props), 'Upload', '`value` is not a valid prop, do you mean `fileList`?');
    warning(!('transformFile' in props), 'Upload', '`transformFile` is deprecated. Please use `beforeUpload` directly.');
    // Control mode will auto fill file uid if not provided
    React.useMemo(() => {
        const timestamp = Date.now();
        (fileList || []).forEach((file, index) => {
            if (!file.uid && !Object.isFrozen(file)) {
                file.uid = `__AUTO__${timestamp}_${index}__`;
            }
        });
    }, [fileList]);
    const onInternalChange = (file, changedFileList, event) => {
        let cloneList = [...changedFileList];
        // Cut to match count
        if (maxCount === 1) {
            cloneList = cloneList.slice(-1);
        }
        else if (maxCount) {
            cloneList = cloneList.slice(0, maxCount);
        }
        setMergedFileList(cloneList);
        const changeInfo = {
            file: file,
            fileList: cloneList,
        };
        if (event) {
            changeInfo.event = event;
        }
        onChange === null || onChange === void 0 ? void 0 : onChange(changeInfo);
    };
    const mergedBeforeUpload = (file, fileListArgs) => __awaiter(void 0, void 0, void 0, function* () {
        const { beforeUpload, transformFile } = props;
        let parsedFile = file;
        if (beforeUpload) {
            const result = yield beforeUpload(file, fileListArgs);
            if (result === false) {
                return false;
            }
            // Hack for LIST_IGNORE, we add additional info to remove from the list
            delete file[LIST_IGNORE];
            if (result === LIST_IGNORE) {
                Object.defineProperty(file, LIST_IGNORE, {
                    value: true,
                    configurable: true,
                });
                return false;
            }
            if (typeof result === 'object' && result) {
                parsedFile = result;
            }
        }
        if (transformFile) {
            parsedFile = yield transformFile(parsedFile);
        }
        return parsedFile;
    });
    const onBatchStart = batchFileInfoList => {
        // Skip file which marked as `LIST_IGNORE`, these file will not add to file list
        const filteredFileInfoList = batchFileInfoList.filter(info => !info.file[LIST_IGNORE]);
        // Nothing to do since no file need upload
        if (!filteredFileInfoList.length) {
            return;
        }
        const objectFileList = filteredFileInfoList.map(info => file2Obj(info.file));
        // Concat new files with prev files
        let newFileList = [...mergedFileList];
        objectFileList.forEach(fileObj => {
            // Replace file if exist
            newFileList = updateFileList(fileObj, newFileList);
        });
        objectFileList.forEach((fileObj, index) => {
            // Repeat trigger `onChange` event for compatible
            let triggerFileObj = fileObj;
            if (!filteredFileInfoList[index].parsedFile) {
                // `beforeUpload` return false
                const { originFileObj } = fileObj;
                let clone;
                try {
                    clone = new File([originFileObj], originFileObj.name, {
                        type: originFileObj.type,
                    });
                }
                catch (e) {
                    clone = new Blob([originFileObj], {
                        type: originFileObj.type,
                    });
                    clone.name = originFileObj.name;
                    clone.lastModifiedDate = new Date();
                    clone.lastModified = new Date().getTime();
                }
                clone.uid = fileObj.uid;
                triggerFileObj = clone;
            }
            else {
                // Inject `uploading` status
                fileObj.status = 'uploading';
            }
            onInternalChange(triggerFileObj, newFileList);
        });
    };
    const onSuccess = (response, file, xhr) => {
        try {
            if (typeof response === 'string') {
                response = JSON.parse(response);
            }
        }
        catch (e) {
            /* do nothing */
        }
        // removed
        if (!getFileItem(file, mergedFileList)) {
            return;
        }
        const targetItem = file2Obj(file);
        targetItem.status = 'done';
        targetItem.percent = 100;
        targetItem.response = response;
        targetItem.xhr = xhr;
        const nextFileList = updateFileList(targetItem, mergedFileList);
        onInternalChange(targetItem, nextFileList);
    };
    const onProgress = (e, file) => {
        // removed
        if (!getFileItem(file, mergedFileList)) {
            return;
        }
        const targetItem = file2Obj(file);
        targetItem.status = 'uploading';
        targetItem.percent = e.percent;
        const nextFileList = updateFileList(targetItem, mergedFileList);
        onInternalChange(targetItem, nextFileList, e);
    };
    const onError = (error, response, file) => {
        // removed
        if (!getFileItem(file, mergedFileList)) {
            return;
        }
        const targetItem = file2Obj(file);
        targetItem.error = error;
        targetItem.response = response;
        targetItem.status = 'error';
        const nextFileList = updateFileList(targetItem, mergedFileList);
        onInternalChange(targetItem, nextFileList);
    };
    const handleRemove = (file) => {
        let currentFile;
        Promise.resolve(typeof onRemove === 'function' ? onRemove(file) : onRemove).then(ret => {
            var _a;
            // Prevent removing file
            if (ret === false) {
                return;
            }
            const removedFileList = removeFileItem(file, mergedFileList);
            if (removedFileList) {
                currentFile = Object.assign({}, file);
                (_a = upload.current) === null || _a === void 0 ? void 0 : _a.abort(currentFile);
                onInternalChange(currentFile, removedFileList);
            }
        });
    };
    const onFileDrop = (e) => {
        setDragState(e.type);
        if (e.type === 'drop') {
            onDrop === null || onDrop === void 0 ? void 0 : onDrop(e);
        }
    };
    // Test needs
    React.useImperativeHandle(ref, () => ({
        onBatchStart,
        onSuccess,
        onProgress,
        onError,
        fileList: mergedFileList,
        upload: upload.current,
    }));
    const { getPrefixCls, direction } = React.useContext(ConfigContext);
    const prefixCls = getPrefixCls('upload', customizePrefixCls);
    const rcUploadProps = Object.assign(Object.assign({ onBatchStart,
        onError,
        onProgress,
        onSuccess }, props), { prefixCls, beforeUpload: mergedBeforeUpload, onChange: undefined });
    delete rcUploadProps.className;
    delete rcUploadProps.style;
    // Remove id to avoid open by label when trigger is hidden
    // !children: https://github.com/ant-design/ant-design/issues/14298
    // disabled: https://github.com/ant-design/ant-design/issues/16478
    //           https://github.com/ant-design/ant-design/issues/24197
    if (!children || disabled) {
        delete rcUploadProps.id;
    }
    const renderUploadList = (button, buttonVisible) => showUploadList ? (React.createElement(LocaleReceiver, { componentName: "Upload", defaultLocale: defaultLocale.Upload }, (locale) => {
        const { showRemoveIcon, showPreviewIcon, showDownloadIcon, removeIcon, previewIcon, downloadIcon, } = typeof showUploadList === 'boolean' ? {} : showUploadList;
        return (React.createElement(UploadList, { prefixCls: prefixCls, listType: listType, items: mergedFileList, previewFile: previewFile, onPreview: onPreview, onDownload: onDownload, onRemove: handleRemove, showRemoveIcon: !disabled && showRemoveIcon, showPreviewIcon: showPreviewIcon, showDownloadIcon: showDownloadIcon, removeIcon: removeIcon, previewIcon: previewIcon, downloadIcon: downloadIcon, iconRender: iconRender, locale: Object.assign(Object.assign({}, locale), propLocale), isImageUrl: isImageUrl, progress: progress, appendAction: button, appendActionVisible: buttonVisible, itemRender: itemRender }));
    })) : (button);
    if (type === 'drag') {
        const dragCls = classNames(prefixCls, {
            [`${prefixCls}-drag`]: true,
            [`${prefixCls}-drag-uploading`]: mergedFileList.some(file => file.status === 'uploading'),
            [`${prefixCls}-drag-hover`]: dragState === 'dragover',
            [`${prefixCls}-disabled`]: disabled,
            [`${prefixCls}-rtl`]: direction === 'rtl',
        }, className);
        return (React.createElement("span", null,
            React.createElement("div", { className: dragCls, onDrop: onFileDrop, onDragOver: onFileDrop, onDragLeave: onFileDrop, style: style },
                React.createElement(RcUpload, Object.assign({}, rcUploadProps, { ref: upload, className: `${prefixCls}-btn` }),
                    React.createElement("div", { className: `${prefixCls}-drag-container` }, children))),
            renderUploadList()));
    }
    const uploadButtonCls = classNames(prefixCls, {
        [`${prefixCls}-select`]: true,
        [`${prefixCls}-select-${listType}`]: true,
        [`${prefixCls}-disabled`]: disabled,
        [`${prefixCls}-rtl`]: direction === 'rtl',
    });
    const renderUploadButton = (uploadButtonStyle) => (React.createElement("div", { className: uploadButtonCls, style: uploadButtonStyle },
        React.createElement(RcUpload, Object.assign({}, rcUploadProps, { ref: upload }))));
    const uploadButton = renderUploadButton(children ? undefined : { display: 'none' });
    if (listType === 'picture-card') {
        return (React.createElement("span", { className: classNames(`${prefixCls}-picture-card-wrapper`, className) }, renderUploadList(uploadButton, !!children)));
    }
    return (React.createElement("span", { className: className },
        uploadButton,
        renderUploadList()));
};
const Upload = React.forwardRef(InternalUpload);
if (process.env.NODE_ENV !== 'production') {
    Upload.displayName = 'Upload';
}
Upload.defaultProps = {
    type: 'select',
    multiple: false,
    action: '',
    data: {},
    accept: '',
    showUploadList: true,
    listType: 'text',
    className: '',
    disabled: false,
    supportServerRender: true,
};
export default Upload;
