import * as React from 'react';
import Tooltip from '../../tooltip';
const EllipsisTooltip = ({ title, enabledEllipsis, isEllipsis, children, }) => {
    if (!title || !enabledEllipsis) {
        return children;
    }
    return (React.createElement(Tooltip, { title: title, visible: isEllipsis ? undefined : false }, children));
};
if (process.env.NODE_ENV !== 'production') {
    EllipsisTooltip.displayName = 'EllipsisTooltip';
}
export default EllipsisTooltip;
