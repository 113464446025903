var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import CheckCircleFilled from '@ant-design/icons/CheckCircleFilled';
import CloseCircleFilled from '@ant-design/icons/CloseCircleFilled';
import ExclamationCircleFilled from '@ant-design/icons/ExclamationCircleFilled';
import LoadingOutlined from '@ant-design/icons/LoadingOutlined';
import classNames from 'classnames';
import { Field, FieldContext, ListContext } from 'rc-field-form';
import useState from 'rc-util/lib/hooks/useState';
import omit from 'rc-util/lib/omit';
import { supportRef } from 'rc-util/lib/ref';
import * as React from 'react';
import { useContext, useMemo } from 'react';
import { ConfigContext } from '../config-provider';
import Row from '../grid/row';
import { cloneElement, isValidElement } from '../_util/reactNode';
import { tuple } from '../_util/type';
import warning from '../_util/warning';
import { FormContext, FormItemInputContext, NoStyleItemContext } from './context';
import FormItemInput from './FormItemInput';
import FormItemLabel from './FormItemLabel';
import useDebounce from './hooks/useDebounce';
import useFrameState from './hooks/useFrameState';
import useItemRef from './hooks/useItemRef';
import { getFieldId, toArray } from './util';
const NAME_SPLIT = '__SPLIT__';
const ValidateStatuses = tuple('success', 'warning', 'error', 'validating', '');
const MemoInput = React.memo(({ children }) => children, (prev, next) => prev.value === next.value && prev.update === next.update);
function hasValidName(name) {
    if (name === null) {
        warning(false, 'Form.Item', '`null` is passed as `name` property');
    }
    return !(name === undefined || name === null);
}
function genEmptyMeta() {
    return {
        errors: [],
        warnings: [],
        touched: false,
        validating: false,
        name: [],
    };
}
const iconMap = {
    success: CheckCircleFilled,
    warning: ExclamationCircleFilled,
    error: CloseCircleFilled,
    validating: LoadingOutlined,
};
function FormItem(props) {
    const { name, noStyle, dependencies, prefixCls: customizePrefixCls, style, className, shouldUpdate, hasFeedback, help, rules, validateStatus, children, required, label, messageVariables, trigger = 'onChange', validateTrigger, hidden } = props, restProps = __rest(props, ["name", "noStyle", "dependencies", "prefixCls", "style", "className", "shouldUpdate", "hasFeedback", "help", "rules", "validateStatus", "children", "required", "label", "messageVariables", "trigger", "validateTrigger", "hidden"]);
    const { getPrefixCls } = useContext(ConfigContext);
    const { name: formName, requiredMark } = useContext(FormContext);
    const isRenderProps = typeof children === 'function';
    const notifyParentMetaChange = useContext(NoStyleItemContext);
    const { validateTrigger: contextValidateTrigger } = useContext(FieldContext);
    const mergedValidateTrigger = validateTrigger !== undefined ? validateTrigger : contextValidateTrigger;
    const hasName = hasValidName(name);
    const prefixCls = getPrefixCls('form', customizePrefixCls);
    // ========================= MISC =========================
    // Get `noStyle` required info
    const listContext = React.useContext(ListContext);
    const fieldKeyPathRef = React.useRef();
    // ======================== Errors ========================
    // >>>>> Collect sub field errors
    const [subFieldErrors, setSubFieldErrors] = useFrameState({});
    // >>>>> Current field errors
    const [meta, setMeta] = useState(() => genEmptyMeta());
    const onMetaChange = (nextMeta) => {
        // This keyInfo is not correct when field is removed
        // Since origin keyManager no longer keep the origin key anymore
        // Which means we need cache origin one and reuse when removed
        const keyInfo = listContext === null || listContext === void 0 ? void 0 : listContext.getKey(nextMeta.name);
        // Destroy will reset all the meta
        setMeta(nextMeta.destroy ? genEmptyMeta() : nextMeta, true);
        // Bump to parent since noStyle
        if (noStyle && notifyParentMetaChange) {
            let namePath = nextMeta.name;
            if (!nextMeta.destroy) {
                if (keyInfo !== undefined) {
                    const [fieldKey, restPath] = keyInfo;
                    namePath = [fieldKey, ...restPath];
                    fieldKeyPathRef.current = namePath;
                }
            }
            else {
                // Use origin cache data
                namePath = fieldKeyPathRef.current || namePath;
            }
            notifyParentMetaChange(nextMeta, namePath);
        }
    };
    // >>>>> Collect noStyle Field error to the top FormItem
    const onSubItemMetaChange = (subMeta, uniqueKeys) => {
        // Only `noStyle` sub item will trigger
        setSubFieldErrors(prevSubFieldErrors => {
            const clone = Object.assign({}, prevSubFieldErrors);
            // name: ['user', 1] + key: [4] = ['user', 4]
            const mergedNamePath = [...subMeta.name.slice(0, -1), ...uniqueKeys];
            const mergedNameKey = mergedNamePath.join(NAME_SPLIT);
            if (subMeta.destroy) {
                // Remove
                delete clone[mergedNameKey];
            }
            else {
                // Update
                clone[mergedNameKey] = subMeta;
            }
            return clone;
        });
    };
    // >>>>> Get merged errors
    const [mergedErrors, mergedWarnings] = React.useMemo(() => {
        const errorList = [...meta.errors];
        const warningList = [...meta.warnings];
        Object.values(subFieldErrors).forEach(subFieldError => {
            errorList.push(...(subFieldError.errors || []));
            warningList.push(...(subFieldError.warnings || []));
        });
        return [errorList, warningList];
    }, [subFieldErrors, meta.errors, meta.warnings]);
    const debounceErrors = useDebounce(mergedErrors);
    const debounceWarnings = useDebounce(mergedWarnings);
    // ===================== Children Ref =====================
    const getItemRef = useItemRef();
    // ======================== Status ========================
    let mergedValidateStatus = '';
    if (validateStatus !== undefined) {
        mergedValidateStatus = validateStatus;
    }
    else if (meta === null || meta === void 0 ? void 0 : meta.validating) {
        mergedValidateStatus = 'validating';
    }
    else if (debounceErrors.length) {
        mergedValidateStatus = 'error';
    }
    else if (debounceWarnings.length) {
        mergedValidateStatus = 'warning';
    }
    else if (meta === null || meta === void 0 ? void 0 : meta.touched) {
        mergedValidateStatus = 'success';
    }
    const formItemStatusContext = useMemo(() => {
        let feedbackIcon;
        if (hasFeedback) {
            const IconNode = mergedValidateStatus && iconMap[mergedValidateStatus];
            feedbackIcon = IconNode ? (React.createElement("span", { className: classNames(`${prefixCls}-item-feedback-icon`, `${prefixCls}-item-feedback-icon-${mergedValidateStatus}`) },
                React.createElement(IconNode, null))) : null;
        }
        return {
            status: mergedValidateStatus,
            hasFeedback,
            feedbackIcon,
            isFormItemInput: true,
        };
    }, [mergedValidateStatus, hasFeedback]);
    // ======================== Render ========================
    function renderLayout(baseChildren, fieldId, isRequired) {
        if (noStyle && !hidden) {
            return baseChildren;
        }
        const itemClassName = {
            [`${prefixCls}-item`]: true,
            [`${prefixCls}-item-with-help`]: (help !== undefined && help !== null) || debounceErrors.length || debounceWarnings.length,
            [`${className}`]: !!className,
            // Status
            [`${prefixCls}-item-has-feedback`]: mergedValidateStatus && hasFeedback,
            [`${prefixCls}-item-has-success`]: mergedValidateStatus === 'success',
            [`${prefixCls}-item-has-warning`]: mergedValidateStatus === 'warning',
            [`${prefixCls}-item-has-error`]: mergedValidateStatus === 'error',
            [`${prefixCls}-item-is-validating`]: mergedValidateStatus === 'validating',
            [`${prefixCls}-item-hidden`]: hidden,
        };
        // ======================= Children =======================
        return (React.createElement(Row, Object.assign({ className: classNames(itemClassName), style: style, key: "row" }, omit(restProps, [
            'colon',
            'extra',
            'fieldKey',
            'requiredMark',
            'getValueFromEvent',
            'getValueProps',
            'htmlFor',
            'id',
            'initialValue',
            'isListField',
            'labelAlign',
            'labelWrap',
            'labelCol',
            'normalize',
            'preserve',
            'tooltip',
            'validateFirst',
            'valuePropName',
            'wrapperCol',
            '_internalItemRender',
        ])),
            React.createElement(FormItemLabel, Object.assign({ htmlFor: fieldId, required: isRequired, requiredMark: requiredMark }, props, { prefixCls: prefixCls })),
            React.createElement(FormItemInput, Object.assign({}, props, meta, { errors: debounceErrors, warnings: debounceWarnings, prefixCls: prefixCls, status: mergedValidateStatus, help: help }),
                React.createElement(NoStyleItemContext.Provider, { value: onSubItemMetaChange },
                    React.createElement(FormItemInputContext.Provider, { value: formItemStatusContext }, baseChildren)))));
    }
    if (!hasName && !isRenderProps && !dependencies) {
        return renderLayout(children);
    }
    let variables = {};
    if (typeof label === 'string') {
        variables.label = label;
    }
    else if (name) {
        variables.label = String(name);
    }
    if (messageVariables) {
        variables = Object.assign(Object.assign({}, variables), messageVariables);
    }
    // >>>>> With Field
    return (React.createElement(Field, Object.assign({}, props, { messageVariables: variables, trigger: trigger, validateTrigger: mergedValidateTrigger, onMetaChange: onMetaChange }), (control, renderMeta, context) => {
        const mergedName = toArray(name).length && renderMeta ? renderMeta.name : [];
        const fieldId = getFieldId(mergedName, formName);
        const isRequired = required !== undefined
            ? required
            : !!(rules &&
                rules.some(rule => {
                    if (rule && typeof rule === 'object' && rule.required && !rule.warningOnly) {
                        return true;
                    }
                    if (typeof rule === 'function') {
                        const ruleEntity = rule(context);
                        return ruleEntity && ruleEntity.required && !ruleEntity.warningOnly;
                    }
                    return false;
                }));
        // ======================= Children =======================
        const mergedControl = Object.assign({}, control);
        let childNode = null;
        warning(!(shouldUpdate && dependencies), 'Form.Item', "`shouldUpdate` and `dependencies` shouldn't be used together. See https://ant.design/components/form/#dependencies.");
        if (Array.isArray(children) && hasName) {
            warning(false, 'Form.Item', '`children` is array of render props cannot have `name`.');
            childNode = children;
        }
        else if (isRenderProps && (!(shouldUpdate || dependencies) || hasName)) {
            warning(!!(shouldUpdate || dependencies), 'Form.Item', '`children` of render props only work with `shouldUpdate` or `dependencies`.');
            warning(!hasName, 'Form.Item', "Do not use `name` with `children` of render props since it's not a field.");
        }
        else if (dependencies && !isRenderProps && !hasName) {
            warning(false, 'Form.Item', 'Must set `name` or use render props when `dependencies` is set.');
        }
        else if (isValidElement(children)) {
            warning(children.props.defaultValue === undefined, 'Form.Item', '`defaultValue` will not work on controlled Field. You should use `initialValues` of Form instead.');
            const childProps = Object.assign(Object.assign({}, children.props), mergedControl);
            if (!childProps.id) {
                childProps.id = fieldId;
            }
            if (supportRef(children)) {
                childProps.ref = getItemRef(mergedName, children);
            }
            // We should keep user origin event handler
            const triggers = new Set([
                ...toArray(trigger),
                ...toArray(mergedValidateTrigger),
            ]);
            triggers.forEach(eventName => {
                childProps[eventName] = (...args) => {
                    var _a, _b, _c;
                    (_a = mergedControl[eventName]) === null || _a === void 0 ? void 0 : _a.call(mergedControl, ...args);
                    (_c = (_b = children.props)[eventName]) === null || _c === void 0 ? void 0 : _c.call(_b, ...args);
                };
            });
            childNode = (React.createElement(MemoInput, { value: mergedControl[props.valuePropName || 'value'], update: children }, cloneElement(children, childProps)));
        }
        else if (isRenderProps && (shouldUpdate || dependencies) && !hasName) {
            childNode = children(context);
        }
        else {
            warning(!mergedName.length, 'Form.Item', '`name` is only used for validate React element. If you are using Form.Item as layout display, please remove `name` instead.');
            childNode = children;
        }
        return renderLayout(childNode, fieldId, isRequired);
    }));
}
export default FormItem;
